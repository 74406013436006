const PrivacyPolicy = () => {
    return (
        <>
            <div className="bg-white min-h-screen flex flex-col items-center">
                <header className="bg-white w-full flex flex-col p-4 shadow-md sticky top-0 z-10">
                    <div className="flex items-center w-full max-w-4xl">
                        <img src="/assets/logo_header.png" alt="University Logo" className="h-20 mr-6" />
                        <img src="./assets/logo_header_2.svg" alt="Faculty Development Logo" />
                    </div>
                </header>

                <main className="max-w-6xl mt-4 pl-4 pr-4 text-left bg-white">
                    <div className="px-4">
                        <h1 className="text-center text-xl font-bold flex items-center justify-center gap-2">
                            <img src="/assets/daiso_icon.svg" alt="Class Plan Eval" width="22" height="22" />
                            プライバシーポリシー
                        </h1>
                        <p className="mt-5 text-base text-gray-700 leading-7">
                            本センターは、法令及び「東京大学の保有個人情報の適切な管理のための措置に関する規則」
                            を始めとする本学規則を遵守し、以下の方針に基づき個人情報の保護に努めます。
                        </p>
                        <div className="mt-4">
                            <a
                                href="https://www.u-tokyo.ac.jp/gen01/reiki_int/reiki_honbun/au07408321.html"
                            >
                                <span className="text-black">•</span>
                                <span className="text-blue-500 underline gap-1 text-base">
                                    東京大学の保有個人情報等の適切な管理のための措置に関する規則
                                </span>
                            </a>
                        </div>
                        <div>
                            <a
                                href="https://www.u-tokyo.ac.jp/gen01/reiki_int/kisoku_mokuji_j.html"
                            >
                                <span className="text-black">•</span>
                                <span className="text-blue-500 underline gap-1 text-base">
                                    東京大学規則集
                                </span>
                            </a>
                        </div>
                    </div>

                    <div className="pt-4 px-4">
                        <h2 className="text-lg font-bold text-gray-900 border-b-4 border-customBlue pb-1">
                            1. 個人情報の取得について
                        </h2>
                        <p className="mt-4 ml-4 text-base leading-7 ">
                            本センターは、取得する個人情報が業務の遂行上必要な場合に限り、かつ、利用目的の範囲を超えないよう
                            に個人情報を取得します。
                        </p>
                        <p className="text-base leading-7 ">
                            取得にあたっては適法かつ公正な手段によりこれを行います。
                        </p>
                    </div>

                    <div className="pt-4 px-4">
                        <h2 className="text-lg font-bold text-gray-900 border-b-4 border-customBlue pb-1">
                            2. 個人情報の利用について
                        </h2>
                        <div className="text-base leading-7 ">
                            <span className="mb-4 pl-4">
                                本セ
                            </span>
                            <span>
                                ンターは、法令に基づく場合を除き、個人情報を事前に本人の同意を得ることなく、利用目的以外の利
                                用や第三者への提供をしません。
                            </span>
                        </div>

                        <div className="text-base leading-7 ">
                            <span className="mb-4 pl-4">
                                また、
                            </span>
                            <span>
                                法令に基づいて、個人情報を第三者に提供し、又は個人情報の取扱いに係る業務を第三者に委託する
                                場合には、当該第三者につき、当該個人情報の安全確保、管理の能力等について厳正な調査を行い、個人情報
                                を取り扱わせるにあたっては適正な監督を行います。
                            </span>
                        </div>
                        <div>
                            <p className="text-base pl-4 leading-7 ">
                                本サイト (<a href="https://www.he.u-tokyo.ac.jp/" className="text-blue-500 underline">https://www.he.u-tokyo.ac.jp/</a>) において取得した個人情報は、以下の目的に利用します。
                            </p>
                        </div>
                        <ul className="list-none text-base leading-7 text-gray-700">
                            <li><span className="font-semibold">（1）</span>本サイトの運営、維持、管理</li>
                            <li><span className="font-semibold">（2）</span>本サイトを通じた問合せ対応</li>
                            <li><span className="font-semibold">（3）</span>その他法令及び本学規則に係る事務を行うため</li>
                        </ul>
                    </div>

                    <div className="pt-4 px-4">
                        <h2 className="text-lg font-bold text-gray-900 border-b-4 border-customBlue pb-1">
                            3. 個人情報の管理について
                        </h2>
                        <div className="text-base leading-7 ">
                            <span className="mb-4 pl-4">
                                本セ
                            </span>
                            <span>
                                ンターは、保有する個人情報の適切な管理を行うとともに、その紛失、破壊、改ざん及び漏えいなどを
                                防止するため、情報セキュリティ対策等の必要な措置を講じ、個人情報の保護に努めます。
                            </span>
                        </div>
                    </div>

                    <div className="pt-4 px-4">
                        <h2 className="text-lg font-bold text-gray-900 border-b-4 border-customBlue pb-1">
                            4. SSL等による安全性の確保について
                        </h2>
                        <div className="text-base leading-7 ">
                            <span className="mb-4 pl-4">
                                本サ
                            </span>
                            <span>
                                イトは、個人情報をご登録いただく時に、SSL等の合理的に行いうる安全性の確保に必要となる手段を講
                                じます。
                            </span>
                        </div>
                    </div>

                    <div className="pt-4 px-4">
                        <h2 className="text-lg font-bold text-gray-900 border-b-4 border-customBlue pb-1">
                            5. 外部サービスの利用について
                        </h2>
                        <div className="text-base leading-7 ">
                            <span className="mb-4 pl-4">
                                本セ
                            </span>
                            <span>
                                ンターは、業務の円滑な運営および効率化のために教育支援システムを開発し、提供しています。
                                (<a href="https://fd.he.u-tokyo.ac.jp/" className="text-blue-500 underline">https://fd.he.u-tokyo.ac.jp/</a>)
                                このシステムにはGoogle Workspace等の外部サービスを利用しております。
                            </span>
                        </div>

                        <div className="text-base leading-7 ">
                            <span className="mb-4 pl-4">
                                これ
                            </span>
                            <span>
                                らのサービスの利用にあたり、本センターは、取得した個人情報を適切に管理し、外部サービスの利用
                                が個人情報保護に関する法令及び本学の規則に反しないよう努めます。
                            </span>
                        </div>

                        <div className="text-base leading-7 ">
                            <span className="mb-4 pl-4">
                                また、
                            </span>
                            <span>
                                本センターはGoogle WorkspaceのAPIを利用していますが、取得したデータは汎用的なAIおよび機械学習
                                （ML）モデルの開発、改善、またはトレーニングには一切使用しません。
                            </span>
                        </div>

                        <div className="text-base leading-7 ">
                            <span className="mb-4 pl-4">
                                本セ
                            </span>
                            <span>
                                ンターは、外部サービスを利用する場合でも、個人情報の保護に最大限配慮し、利用者の権利を尊重い
                                たします。
                            </span>
                        </div>

                        <div className="text-base leading-7 ">
                            <span className="mb-4 pl-4">
                                詳細は以下の通りです。
                            </span>
                        </div>

                        <div className="text-base leading-7 mb-4 pl-4 mt-4">
                            <p className="font-semibold">5.1. アクセスするGoogleユーザーデータについて</p>
                            <p>当アプリケーションでは、以下のGoogle APIを利用してデータを取得・使用します。</p>
                            <ul className="text-base leading-7 pl-4">
                                <li><span className="font-semibold">• Google Calendar API: </span>カレンダー情報を取得し、スケジュール機能を提供します。</li>
                                <li><span className="font-semibold">• Google Drive API: </span>ドライブ内のファイルを取得して操作をサポートします。</li>
                                <li><span className="font-semibold">• YouTube API: </span>動画をYouTubeに非公開モードでアップロードするために使用されます。</li>
                            </ul>
                            <p>取得したデータは、アプリケーションの動作をサポートする目的のみに使用されます。</p>
                        </div>

                        <div className="text-base leading-7 mb-4 pl-4 mt-4">
                            <p className="font-semibold">5.2. Googleユーザーデータの保存および共有について</p>
                            <ul className="text-base leading-7 pl-4">
                                <li><span className="font-semibold">• データの保存</span></li>
                            </ul>
                            <p>
                                <span>
                                    取得したGoogleユーザーデータは、アプリケーション内でリアルタイムに利用されますが、データベースには保存されません。
                                </span>
                            </p>
                            <ul className="text-base leading-7 pl-4">
                                <li><span className="font-semibold">• データの共有</span></li>
                            </ul>
                            <p>
                                Googleユーザーデータは、当システム内でのみ使用され、第三者や他のユーザーに共有、転送、または開示されることは一切ありません。
                            </p>
                        </div>

                        <div className="text-base leading-7 mb-4 pl-4 mt-4">
                            <p className="font-semibold">5.3. Googleユーザーデータの保持および削除について</p>
                            <ul className="text-base leading-7 pl-4">
                                <li><span className="font-semibold">• 保持期間</span></li>
                            </ul>
                            <p>
                                Googleユーザーデータは、サービス提供中にリアルタイムでアクセスされ、使用後は保持されません。
                            </p>
                            <ul className="text-base leading-7 pl-4">
                                <li><span className="font-semibold">• 削除ポリシー</span></li>
                            </ul>
                            <p>
                                ユーザーがデータ削除を希望する場合、当アプリケーションの利用を停止することで、データへのアクセスが停止されます。
                            </p>
                        </div>

                        <div className="text-base leading-7 mb-4 pl-4 mt-4">
                            <p className="font-semibold">5.4. セキュリティ対策</p>
                            <p>当アプリケーションでは、Googleユーザーデータを保護するために以下のセキュリティ対策を講じています。</p>
                            <ul className="text-base leading-7 pl-4">
                                <li><span className="font-semibold">• </span>データ通信の暗号化（HTTPS）</li>
                                <li><span className="font-semibold">• </span>APIキーおよび認証情報の安全な管理</li>
                            </ul>
                        </div>
                    </div>

                    <div className="flex justify-end pt-4 px-4 mb-4">
                        <img src="/assets/logo_daiso.svg" alt="Class Plan Eval" />
                    </div>
                </main>
            </div>
        </>
    )
}

export default PrivacyPolicy;